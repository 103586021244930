<template>
  <div class="c-library">
    <div class="c-library__title c-library__title-blog">
      <h2 class="c-library__title-subtitle">Catch all the latest news bytes on</h2>
      <h1 class="c-library__title-text">Sparrow's blog</h1>
    </div>
    <div class="c-library__description">
      <p class="c-library__description-text">Stay updated with useful tips on options trading and cryptocurrency activities in Singapore, the latest market updates and growth trends on digital assets.</p>
    </div>
    <Blog></Blog>

  </div>
</template>

<script>
  import Blog from '@/components/library/Blog.vue'
  export default {
    name: 'SparrowBlogContent',
    components: {
      Blog,
    },
    data() {
      return {

      }
    },
  }
</script>
