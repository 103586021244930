<template>
  <div class="page">
    <div class="c-page o-page-wrapper c-page_partner">
      <Sidebar :status="'library'" :elementClass="'.c-getstarted'"></Sidebar>
      <div class="o-sidebar-content">
        <PageBanner :bannerData = "bannerData" :theme="theme"></PageBanner>
        <div class="c-page__content_top">
          <Content></Content>
          <LikeSuggestion></LikeSuggestion>
          <GetStartedContent></GetStartedContent>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue'
import Footer from '@/components/Footer.vue'
import PageBanner from '@/components/PageBanner.vue'
import GetStartedContent from '@/components/GetStartedContent.vue'
import LikeSuggestion from '@/components/LikeSuggestion.vue'
import Content from '@/components/library/SparrowBlogContent.vue'

export default {
  name: 'SparrowBlog',
  components: {
    Sidebar,
    Footer,
    PageBanner,
    GetStartedContent,
    LikeSuggestion,
    Content
  },
  data() {
    return {
      bannerData: [
        {
          img : require('@/assets/images/banner/Diverse range of innovative products.jpg'),
          title:"Diverse range of innovative <br />products <span class='c-page-banner__regular'>for institutions and</span>",
          subtitle:"accredited clients",
          buttonText:"Find out more >",
          buttonPage:"/institutional/wealth"
        },
      ],
      theme: 'two',
    }
  },
  mounted:function(){
    this.updateTitleMeta('Sparrow | Market Updates and Essential Facts on Digital Assets')
    this.updateDescriptionMeta('Sparrow shares useful and relevant market insights, the latest trends in digital asset solutions, and cryptocurrency option trading strategies in Singapore.')
    this.updateKeywordMeta('Sparrow, sparrow exchange, options trading, cryptocurrenies activities in Singapore, wealth management')
  }
}
</script>
