<template>
  <div class="c-library__body">

    <div class="c-library__blog">
      <div class="c-library__blog-item" v-for="(dt, i) in blogList" :key="i" @click="openLink(dt.link)">
        <img class="c-library__blog-img" :src="dt.img" alt="blog-image"/>
        <div class="c-library__blog-content" >
          <h3 class="c-library__blog-title">{{ dt.title }}</h3>
          <p class="c-library__blog-detail">{{ dt.date }} | {{ dt.category }}</p>
        </div>
      </div>
    </div>
    <a href="https://blog.sparrowexchange.com/" target="_blank" class="c-library__blog-see-more">See all</a>
  </div>
</template>

<script>
  export default {
    name: 'Blog',
    data() {
      return {
        blogList:[
          {
            img:require('@/assets/images/blog/sparrow-blog-cryptocurrency.svg'),
            date:'September 13th, 2022',
            title:'Compliance at the forefront of cryptocurrency',
            category:'Knowledge',
            link:'https://blog.sparrowexchange.com/compliance-at-the-forefront-of-cryptocurrency/'
          },
          {
            img:require('@/assets/images/blog/sparrow-blog-mpi.svg'),
            date:'August 16th, 2022',
            title:'Why is a Major Payment Institution (MPI) licence a key evaluation criterion when selecting a digital assets partner?',
            category:'Knowledge',
            link:'https://blog.sparrowexchange.com/why-is-a-major-payment-institution-mpi-licence-a-key-evaluation-criterion-when-selecting-a-digital-assets-partner/'
          },
          {
            img:require('@/assets/images/blog/sparrow-blog-cryptocurrency-drive.svg'),
            date:'July 21th, 2022',
            title:'How can cryptocurrency drive innovation and efficiency within the financial industry?',
            category:'Knowledge',
            link:'https://blog.sparrowexchange.com/how-can-cryptocurrency-drive-innovation-and-efficiency-within-the-financial-industry/'
          },
          {
            img:require('@/assets/images/blog/sparrow-blog-cryptocurrency-regulations.svg'),
            date:'June 29th, 2022',
            title:'How do cryptocurrency regulations in Singapore benefit financial institutions?',
            category:'Knowledge',
            link:'https://blog.sparrowexchange.com/how-do-cryptocurrency-regulations-in-singapore-benefit-financial-institutions/'
          },
          {
            img:require('@/assets/images/blog/sparrow-blog-cryptocurrency-bridging.svg'),
            date:'May 17th, 2022',
            title:'In conversation with Sparrow’s Head of Trading: Bridging cryptocurrency and traditional finance',
            category:'Knowledge',
            link:'https://blog.sparrowexchange.com/in-conversation-with-sparrows-head-of-trading-bridging-cryptocurrency-and-traditional-finance/'
          },
        ],
      }
    },
  }
</script>
